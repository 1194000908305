import React from 'react';
import './styles/PoemCard.css';

function PoemCard({ title, content, author, fullContent }) {

  return (
    <div className="card-poem">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{content}
        </p>
        <p className="text-muted">- {author}</p>
      </div>
    </div>
  );
}

export default PoemCard;
