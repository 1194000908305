const poems = [
    { 
      title: 'De olhos fechados', 
      content: 'A vida passava. O sol se estendia. Pessoas lutavam. Silêncios sumiam...', 
      author: 'Paula do Carmo', 
      fullContent: `Enquanto dormia
  
  A vida passava.  
  O sol se estendia.  
  Pessoas lutavam.  
  Silêncios sumiam.
  
  Sonhos se dissipavam.  
  Vozes emudeciam.  
  A surdez se alastrava.  
  A visão se escondia.
  
  A decisão se adiava.  
  A pressa se aquietava.  
  A esperança fugia.  
  E a morte não vinha.
  
  
  Quando acordei  
  Vida, sol, desatinos,  
  Silêncio, Sonhos,  
  Vozes, surdez, visão,  
  Decisão, pressa, esperança.
  
  
  Rio que passa e não descansa.  
  É vida em retalhos  
  Retalhos são somente lembranças.  
  E nada da morte.  
  E nada da vida.
  
  É sempre noite.
  E noite de sol a pino. 
  Preciso dormir.`
    },
    { 
      title: 'Ilusão', 
      content: 'Quem dera tirar da vida, O copo de voz ardida, O sabor de mágoa que cresce, A dor que a ilusão adormece...', 
      author: 'Paula do Carmo',
      fullContent: `Quem dera tirar da vida
O copo de voz ardida
O sabor de mágoa que cresce
A dor que a ilusão adormece.

Quem dera tirar da vida
A fumaça da vida varrida
O delírio insano, a tragada
A morte já anunciada.

Quem dera tirar da vida
O cheiro da alma perdida
O soluço que a fêmea enternece
O olhar da cria
Que ainda em vida, adormece.

Quem dera tirar da vida
Os passos da insensatez
Do tom a rude altivez
A ruga ferrada na tez.

Quem dera tirar da vida
O susto do sono inocente
Que precocemente entende
O copo, a fumaça e o cheiro
Da alma as dores vigentes.` 
    },
    { 
      title: 'Decisões', 
      content: 'Às vezes siso, Do avesso riso, Do direito soluço. Às vezes descanso, Do avesso cansaço, Do direito corrente. Às vezes demência,...', 
      author: 'Paula do Carmo',
      fullContent: `Às vezes siso,
Do avesso riso,
Do direito soluço.
Às vezes descanso,
Do avesso cansaço,
Do direito corrente.

Às vezes demência,
Do avesso inocência,
Do direito razão.

Às vezes indiferença,
Do avesso premência,
Do direito amor.

Às vezes valentia,
Do avesso teimosia,
Do direito insanidade.

Às vezes chegada,
Do avesso partida,
Do direito vida.
 
Às vezes benzição,
Do avesso maldição,
Do direito morte.

Nunca acaso, sorte ou destino,
Do direito e do avesso,
Arbítrio e caminho.`
    },
    {
      title: 'Olhos de Coruja',
      content: 'Olhar de quem nasceu para entender... Perguntar, aprender. Olhar de quem não conhece, Mas que quer conhecer. Olhar de quem quer viver.',
      author: 'Paula do Carmo',
      fullContent: `Olhar de quem nasceu para entender...
Perguntar, aprender.
Olhar de quem não conhece,
Mas que quer conhecer.
Olhar de quem quer viver.


O horizonte é pouco pra ti
Acho que vás inventar um tempo,mais um tempo...
O hoje não é suficiente,
Precisas de mais uma manhã.

És filho de gente forte,
De voz que atravessa muros,
De braços que abrem caminhos.
Quem é tão sábio e gentil?

Deixa eu falar um pouquinho,
Cala, para e ouve menino,
Criança com olhos de festa,
De dança em tempo junino.`
    },
    {
      title: 'Essa tal de correnteza',
      content: 'Por que andar tão depressa? Levando e rasgando o barranco, Deixando a terra caída?',
      author: 'Paula do Carmo',
      fullContent: `Por que andar tão depressa?
Levando e rasgando o barranco
Deixando a terra caída?

Por que tantas margens desfeitas
Se antes eram perfeitas
Assim fez o criador.

Mas se é pra ser virgem,
Por que criou a correnteza?

Por que não é manso o remanço?
- porque tem correnteza.

Por que é tão bravo o rebojo?
- porque tem correnteza.

Tanto casco furado,
Tanto barco virado.
Aqui tem correnteza.
 É madeira, corredeira,
É árvore morta.

É cobra-grande que passa
Em noites de lua cheia
Rasgando a noite brilhante,
Fazendo visagem no rio.

É cobra-grande que assusta,
Com costas de cuia queimada
Reluzente ao luar
Faiscando nos olhos,
Arrebentando o peito do caboclo,
E ensurdecendo o silêncio.

É... Até mesmo a boiúna
A correnteza criou,
Nas águas escuras do rio
Nas noites com brilho de lua.

Essa tal de correnteza...`
    },
    {
      title: 'Amazônia Cabocla',
      content: 'Digam aos homens gigantes, Que patrimônio do mundo é o sol, Pulmão do mundo é o mar',
      author: 'Paula do Carmo',
      fullContent: `Digam aos homens gigantes
Que patrimônio do mundo é o sol,
Pulmão do mundo é o mar

Que este chão de folhas sombrias
Cor de laranja e cinza
Úmidas e putrefatas,
É a carne que cobre os ossos
Do dono da floresta,
Que tem cheiro de barro,
E sabor de peixe
Na folha da bananeira

Sua alma é terra preta
Dos insetos que alimentam
E sustentam as raízes
Das vidas que virão.
É lama que gera a vida.

O dono da floresta
Tem sangue de seiva
Parem de sangrar nossas veias
Parem de arrancar nossas raízes

A raiz é o sustento da floresta
A raiz é o levantar do homem do sol
De pele amorenada
De alma de folha verde-sereno

Arranquem a raiz da floresta
E enterrarão o caboclo.`
    },
    {
      title: 'Sensibilidade',
      content: 'Às vezes pergunto a mim mesma:, Onde mora a sensibilidade?, Na razão, no ato de pensar?',
      author: 'Paula do Carmo',
      fullContent: `Às vezes pergunto a mim mesma:
Onde mora a sensibilidade?
Na razão, no ato de pensar?
No coração, na capacidade de se emocionar?
Na alma, na ação de orar e pedir pelo outro?
Não sei...a razão, o coração, a alma...

Para alguns a sensibilidade
É pensamento, capacidade, ação.

Hoje aprendi que sensibilidade
É hóspede terna do olhar
É ação permanente das mãos.
É angústia injusta da alma.
É gozo incessante do corpo.

É cuidado constante
É presença mesmo distante
É gesto em silêncio
É consciência de humanidade,
Manifestada na emoção, no pensar, na razão.

É lágrima incontida que rola pela face,
Enxugada com a mão.
É lágrima que acalma o coração,
E engole o soluço.

Que pulsa. Que pulsa. Que pulsa.

Quantas vidas resgatadas,
Se a lágrima adornasse o sorriso,
Se a mão levantasse da queda,
Curasse a ferida,
Quebrasse a couraça do grito,
Rompesse a surdez da insensatez,
Sentasse no banco da sensibilidade
E ouvisse o pedido de socorro.

A voz da mágoa, produto da humilhação
A voz do constrangimento,
Produto da falta de reconhecimento.

Aprendi, enfim,
Que cada ferida aberta,
Cada dor soluçada,
Cada lágrima revelada,
Somente pode ser curada,
Quando com amor e verdade,
For tratada na alma,
Com o bálsamo da sensibilidade.`
    },
    {
      title: 'Paredes',
      content: 'Dentro das paredes mudas, Dentro das paredes surdas, Dentro das paredes sujas.',
      author: 'Paula do Carmo',
      fullContent: `Dentro das paredes mudas
Dentro das paredes surdas
Dentro das paredes sujas.

Manchadas de sangue,
Desenhadas de gritos,
As almas não esqueceram as armas,
A dor, o pranto, o susto e a loucura insana.

É necessário lembrar pra não deixar esquecer
Os que ouviram o canto e choraram o pranto,
Mas escolheram viver, mesmo sem nunca mais acordar.

Esses dormem ao sol das idéias,
Mesmo entre as paredes escuras.
Das águas profundas e das covas rasteiras,
Solidão parceira,
Morada de irmãos de sonho e de voz.

Amigos que ouviram o canto,
Cantaram o pranto e escolheram viver,
Mesmo sem nunca mais acordar.

É preciso gritar, lançar o canto, cantar,
Abrir as janelas e mostrar as paredes
Elas precisam falar.
É imprescindível ouvir,
“mais depressa que imediatamente”,
Como diz meu sensato pai.

As parideiras já não choram,
Não tem mais lágrimas.
Suas almas transparentes,
Consumidas pela dor.

Mataram o ser de suas entranhas
Perderam o riso, o siso, já não sabem gritar,
Já não conseguem dormir.

Mas muitos ainda dormem.
Não ouviram o canto
Não choraram o pranto.
Esses, escolheram morrer.`
    },
    {
      title: 'Atualidades',
      content: 'A canção, a cantoria, a fala, A hemorragia - disenteria. Aliás, não quero aqui',
      author: 'Paula do Carmo',
      fullContent: `A canção, a cantoria, a fala,
A hemorragia - disenteria.
Aliás, não quero aqui
Ofender o intestino,
Que faz aí sua função.

Expurga as ervas que o gado engole
E passa por grande tensão.
Com dor expreme a grama,
A grana, a pasta, a coca,
A cola, a bola.

E ainda tem o somzão.
São AM's e FM's
Que empurram pela audição
As vaquinhas e as cachorras
O martelo, o batidão
O lêlêlê e as brabuletas
E tudo sem passar pelo estômago.

Imagine o desafio do intestino
Que tem o triste destino
De o corpo purificar.
Da canção, da cantoria
Da hemorragia
No dia do AVC.

Do cardápio repetido,
Dos sabores conhecidos,
Na mesa dos “Justos”
que podem pagar.

Das genitálias hermafroditas
Tão precocemente vendidas
Sem nomes, nem sobrenomes.

O paraíso, já há muito foi fechado
Está locado ou comprado
Quem sabe foi terceirizado.
Quem se importa?

O trabalho é sol, é lua,
É alfa e ômega.
O resto é caminho e carroça
É orgulho de ser corcel.

Assume então, intestino
O que te cabe, o destino
Expurga... Expurga...`
    },
    {
      title: 'Evolução',
      content: 'Este, dizem as corujas, É um tempo de vestir a camisa, De conversar com a brisa',
      author: 'Paula do Carmo',
      fullContent: `Este, dizem as corujas,
É um tempo de vestir a camisa
De conversar com a brisa
De entender o tempo,
o caminho, o vento.

É um tempo de metamorfoses
De almas verdes,
De almas e armas brancas.
É um tempo de aristoteses.
É um tempo novo este tempo...

Mas que novo tanto ele tem?
Se a pergunta ainda é a mesma
E a resposta ainda graceja
E não se deixa encontrar?

O que é novo no tempo,
Se o princípio estudou na jônia,
E desde o princípio?

Tantos sóis, tantas luas.
Quanto tempo ainda buscar,
Quanto tempo ainda esperar?

O fim é green, devir, transformação,
Holocausto, circuncisão.
A ovelha, o algoz, a hipertensão.
Esse é o homem na linha de produção.

O tempo, aprendi
É a coruja que vê,
A luz, a escuridão.

O homem é a fase
A natureza é o cenário
Em renovação?

O caminho não morre.
O que morre é o homem,
O dinossauro, a ilusão.

Afinal, o que é novo no tempo?
A evolução?!`
    },
    {
      title: 'Mãe é água',
      content: 'Nas lembranças da infância, A mesa arrumada, Toalhas limpas, chão de cinza riscado, Depois de maçã bem vermelha.',
      author: 'Paula do Carmo',
      fullContent: `Nas lembranças da infância,
A mesa arrumada,
Toalhas limpas, chão de cinza riscado,
Depois de maçã bem vermelha.
Cheiro de forno e fubá, leite de padre,
Canções de beijos de ameixa.

TV ou pão com manteiga? TV.
Roupa velha, um banquete,
Servido com risos e gratidão.
Feio não existe, somente conhecia a beleza.

Cresci nos quintais de coqueiros,
Com couves e goiaba verde
Brincando com roupa de sol
Que nasce pra todos,
Presente de Deus.

Nas festas de santo,
vestido de sobras,
Amor, gratidão.

Infância rica,
Riqueza ensinada.
Juventude pobre,
Pobreza aprendida.

Minha paixão pela água
Me fez conhecer a tristeza
Do sepulcro gelado,
Da irmã amada perdida,
Saudades da vida.
Mas mostrou-me a alegria
Dos banhos e risos nos rios.

Água no silêncio de portas encostadas,
Água em silêncio que corre mansa
Sem zanga e sem gritos.


Risos e aconchegos, carinho.
Voz grossa e austera
Correnteza quebrada em sua força,
no poder da mãe água.
 És a água que corre entre as pedras
Que poli e lapida em mansidão e silêncio.
Ser a filha primeira
É angústia feliz, é justo e injusto
É brincadeira responsável
Quando te ausentas.

O rio da vida não para e muitas águas passaram,
Mas ainda não aprendi a ser água de lago
E acabo correndo como as águas do rio,
E às vezes, ou muitas vezes,
Penso ainda como correnteza.

Mas não desististe de mim,
Confiaste que um dia eu aprendia.
Mas como água de corredeira,
Com revolta, me rebelei.
Mas soluçando calei, esperei.
Acreditei na mãe água.
 

És mãe, a beleza, a esperança perdida,
A mansidão e a força da água na vida.
Na maternidade sou tua gêmea,
Aprendi a sorrir, chorar e lutar.

Me ensinaste a cumprir a promessa
Feita no altar,
Ainda na inocência.

Quando durmo em teu quarto,
Sou ainda a filha primeira.
Quando cuidas de mim,
Me sinto egoísta, pois tu também me precisas.

Mas a doçura de tua voz,
Me faz  tão dependente de ti.
O conforto de teus braços,
Me faz ficar, sem vontade de ir.


Ai! Tão apertado meu peito,
É saudade de mãe.

Não tem jeito.`
    },
    {
      title: 'Evolução',
      content: 'Este, dizem as corujas, É um tempo de vestir a camisa, De conversar com a brisa, De entender o tempo, o caminho, o vento.',
      author: 'Paula do Carmo',
      fullContent: `Este, dizem as corujas,
É um tempo de vestir a camisa
De conversar com a brisa
De entender o tempo,
o caminho, o vento.

É um tempo de metamorfoses
De almas verdes,
De almas e armas brancas.
É um tempo de aristoteses.
É um tempo novo este tempo...

Mas que novo tanto ele tem?
Se a pergunta ainda é a mesma
E a resposta ainda graceja
E não se deixa encontrar?

O que é novo no tempo,
Se o princípio estudou na jônia,
E desde o princípio?

Tantos sóis, tantas luas.
Quanto tempo ainda buscar,
Quanto tempo ainda esperar?

O fim é green, devir, transformação,
Holocausto, circuncisão.
A ovelha, o algoz, a hipertensão.
Esse é o homem na linha de produção.

O tempo, aprendi
É a coruja que vê,
A luz, a escuridão.

O homem é a fase
A natureza é o cenário
Em renovação?

O caminho não morre.
O que morre é o homem,
O dinossauro, a ilusão.

Afinal, o que é novo no tempo?
A evolução?!`
    },
    {
      title: 'O andarilho dos sonhos',
      content: 'Vou contar uma história, Que todos precisam saber, Das andanças desse pai, Seus caminhos conhecer.',
      author: 'Paula do Carmo',
      fullContent: `Vou contar uma história
Que todos precisam saber
Das andanças desse pai,
Seus caminhos conhecer.

Quando nasceste, andarilho,
Em meio à imensidão do azul.
Tinhas campo, rios e florestas
E no céu, o cruzeiro do sul.

Mas não era para ti
Ou teu aquele lugar.
Teu caminho destinado
Havias de procurar.

Tinhas que andar um bocado
Mesmo sem condutor,
Com teu “bem” aprendeste,
A fazer tudo com amor.

A tua maior certeza,
O que devias ensinar,
Pensavas que não sabias
Mas foste logo buscar.
Não pensaste nas barreiras
Naquilo que irias passar.

Vieste do mato
Aprendendo com a vida
A esperança sentida.

Navegaste rios, dirigiste estradas
À luz de teus filhos, teu suor enxugavas.
Já não mais existias.
Eras somente e principalmente meu pai.

Um pai que ensina e aprende
Que pergunta a tabuada
E sabe ser exigente:
- É preciso estudar
Para aprender a ser gente.

- Leiam bem alto meus filhos
Falem bem alto a lição,
Assim posso aprender
Com vocês a preleção.

Assim foi pelos caminhos
O sábio e amoroso andarilho
Por onde passava aprendia
E ensinava o que vivia.

Assim chegou na cidade
Com panela, prato e esperança
O futuro seu alimento
Esforço que não apaga o tempo.

E seus filhos estudaram
E uma ficou no caminho,
Aprendeu com as corujas
A construir seu destino.

E mais longe foi o andarilho
Sábio como ele só
Cada dia tecendo o futuro
Não dava ponto sem nó.

E veio ele do mato
E chegou na capital
Desfez e montou sua casa
Abrindo paredes no quintal.

E fez isso quantas vezes
Certamente não vai lembrar.
Somente tem a certeza
Do seu dever de caminhar
Ensinando a quem quer ouvir
Que o medo é preciso enfrentar.

Deixou trabalho e salário
Por seu caráter e valor
“Comigo não gritas mais
Fale direito meu senhor”.

Assim com afinco e respeito
Moradias construiu
Trabalhando de sol a sol
O resultado surgiu.

Sua casa meu pai amado
Tem em cada tijolo plantado
Cada passo que tu deste
No caminho que foi buscado.

Conduziste cada filho
E cada um foi muito além
E teus olhos ainda verão teus netos
Mostrarem a força que tens.

Tua semente está em todo canto
Em tua terra, do norte ao sul
Quem duvidar olhe nas fotografias
Elas falarão de tuas alegrias.

Na terra da garoa
Na cidade maravilhosa
Na terra que dirige o povo
E ainda com muita certeza,
Amanhã o teu sonho estará
Na cidade de Fortaleza.

É caboclo andarilho,
Não sabes a força que tens
Pois Deus mandou-te uma estrela
Que a te lado só faz o bem.

Não sei se chegarei onde sonho
Mas sei que chegarei bem além
Porque tiveste a coragem
Que muitos outros não têm.

Saíste sem rumo ou consciência
Do que irias encontrar
Mas sabias que a vitória,
Cabe a quem sabe procurar.`
    },
    {
      title: 'Mas não sem chorar',
      content: 'Você chegou em meio a ventos, Chuvas e trovoadas. E nunca mais minha vida foi a mesma. Eu nem sei quem era antes de você.',
      author: 'Paula do Carmo',
      fullContent: `Você chegou em meio a ventos,
Chuvas e trovoadas.
E nunca mais minha vida foi a mesma.
Eu nem sei quem era antes de você.
A vida? Como a vivi antes de você,
Não dá pra imaginar.

Como alguém descobre o sol
E corre o risco de ficar no escuro?

Escuro é a distância de você,
Escuro é o dia sem falar contigo,
Escuro é uma lágrima no teu rosto.
Escuro é uma vida sem saber
Que você sente minha falta.

Escuro é não te ouvir me chamar de mãe minha
Escuro é não ser tão grande pra te esconder da dor,
Do perigo, desamor.

Você não é desse mundo,
Nem do cosmo que conheço.
Você vem de minha parte mais bonita,
De minha virtude mais primorosa,
De meu gesto mais divino.

Você é a materialização da beleza dos olhos de seu pai
Meu amor incondicional.
Do ser que me amou tão imensamente que gerou você.

Você é meu prazer mais intenso
É o extase da alma, da canção.
É o toque de saudade,
Da falta que sinto da juventude mais forte.

Em você sou a beleza, a eternidade
Em você posso tudo que desejar
No seu riso tímido e envergonhado
Sou a bênção de poder sonhar.

Te amo tanto tu
Que consigo sorrir e chorar
tudo junto.

Não sei, não fica com medo
Eu sei deixar você ir,
Mas não sem chorar. Perdoa vai!`
    },
    {
      title: 'Diamante ao sol',
      content: '`Não consigo te proteger. E a escuridão me deixa perdida. Não consigo te esconder da vida. Teu olhar, meu diamante ao sol.',
      author: 'Paula do Carmo',
      fullContent: `Não consigo te proteger.
E a escuridão me deixa perdida.
Não consigo te esconder da vida.
Teu olhar, meu diamante ao sol.
Teu brilho me encanta, me engana e foges.

Luz que me cega os olhos,
Amor que bate surdo em meu peito,
Me confunde as idéias,
E não me deixa pensar.
És azougue e me escapas.
És ouro, mas não queres saber.
Não posso te proteger.
Teu sorriso me engana e foges.
Não és meu, não queres ser meu.

Meus braços ... tão curtos,
Meus passos ... tão lentos,
Meu coração ... tão fraco e surdo,
Meu desespero ... insuportável.

Por ti não sei gritar, somente choro.
O silêncio dói tanto. É escuridão.
Isso é tudo é tão insano.
O som do silêncio me ronda,
A escuridão me ameaça,
Me deixa sem vida, sem paz.

És tão precioso. Mas tão frágil.
Não posso nada, a não ser te amar.
Não és meu, não queres ser só meu.
Às vezes penso que és ingrato.
Ou sou eu, imensuravelmente egoísta
Em querer te prender em meus braços?

Hoje cheguei, não estavas
De novo o medo, a escuridão, o silêncio.
Uma prece exclamo, gritando.
Meu corpo estremece.
Meu olhar sem rumo.

Onde estás, seiva e sangue?
Não fujas mais,
Tenho medo de não te alcançar.

Por que não me ouves?
Não vês que te amo,
Que és meu encanto.
Por que foges?
Por que corres perigo?

Dói tanto o silêncio.
É tão escuro em meu peito
O som da perda me assombra.
Tanto medo, não durmo.

É tão doce te ver dormir.
Te abraçar,
Teu coração sentir.
Fique aqui e não fujas.
Tenho medo de te perder
Por não poder te alcançar.

O silêncio me ensurdece,
Me estremece a escuridão.
Nasceste sem dor. Minha vida pela tua.
Mas teu choro me acordou.
Chamaste por mim, querias viver.
Me deixa cuidar de ti. Me deixa ficar contigo.

Não fujas mais, tenho medo de não te alcançar.
Não me desesperes mais.
Volta e mora novamente em meu ventre.
Morada onde és somente meu, somente meu.
- Por favor, respira meu filho!`
    },
    {
      title: 'Já chegou o sol',
      content: 'Era manhã, bem cedo, Em meio ao silêncio do sono. Minha prole no ninho, Coração em canto na floresta.',
      author: 'Paula do Carmo',
      fullContent: `Era manhã, bem cedo,
Em meio ao silêncio do sono.
Minha prole no ninho,
Coração em canto na floresta.

Uma canção doce quebrava o silêncio.
Pelo princípio chamava.
“Acorda já abri a janela, já chegou o sol”.
Ouvi de uma voz agridoce.
Uma voz de semente,
Um olhar de esperança,
Uma insaciável sede de viver, de crescer.

O sol já chegou. Mas chegou, não somente hoje.
Chegou quando te senti e nem sabia que estavas lá.
Desde meu ventre tu anuncias o sol.

Minha pele te ouve e dá seu sinal.
O pelo eriçado avisa,
O sol acordou, devo semear
O semear é meu, o colher é teu,
Porque “já chegou o sol”.
O anúncio é para todos
E para cada um.

Quem dorme, foge.
Acorda, abre a janela
Lança o canto, anuncia o sol
Levanta e vive.

“Acorda já chegou o sol”
Ouvi de uma voz de semente,
De um riso alto sem medo de fazer barulho.
Nasceste pra fazer acordar meu olhar de esperança.

A cada dia, já sabe ela
É preciso acordar e anunciar o sol.
Para continuar a “tecer a manhã”.

E novamente ouço,
“Acorda, já abri a janela já chegou o sol”
Anuncia a Lua, incessantemente.

“Bom dia Lua, já acordei”.`
    },
    {
      title: 'Outono',
      content: 'Não conheci o outono, Só vivi nos filmes, Só senti nas fotografias.',
      author: 'Paula do Carmo',
      fullContent: `Não conheci o outono,
Só vivi nos filmes,
Só senti nas fotografias.

É um tempo de folhas verves,
Amarelas, laranjas,
Vermelhas, cinzas...

Só não sei pra que tantas cores,
Se é tudo tão frio,
Conheço somente o frio

Só sei que as folhas caem,
Que a tarde é silêncio,
Que o silêncio é canto,
E o cinza, acalanto

Afinal... Quando vai ser primavera?
Só conheci o outono,
Só senti nos filmes,
Só vivi nas fotografias.`
    },
    {
      title: 'Evolução',
      content: 'Este, dizem as corujas, É um tempo de vestir a camisa, De conversar com a brisa',
      author: 'Paula do Carmo',
      fullContent: `Este, dizem as corujas,
É um tempo de vestir a camisa
De conversar com a brisa
De entender o tempo,
o caminho, o vento.

É um tempo de metamorfoses
De almas verdes,
De almas e armas brancas.
É um tempo de aristoteses.
É um tempo novo este tempo...

Mas que novo tanto ele tem?
Se a pergunta ainda é a mesma
E a resposta ainda graceja
E não se deixa encontrar?

O que é novo no tempo,
Se o princípio estudou na jônia,
E desde o princípio?

Tantos sóis, tantas luas.
Quanto tempo ainda buscar,
Quanto tempo ainda esperar?

O fim é green, devir, transformação,
Holocausto, circuncisão.
A ovelha, o algoz, a hipertensão.
Esse é o homem na linha de produção.

O tempo, aprendi
É a coruja que vê,
A luz, a escuridão.

O homem é a fase
A natureza é o cenário
Em renovação?

O caminho não morre.
O que morre é o homem,
O dinossauro, a ilusão.

Afinal, o que é novo no tempo?
A evolução?!`
    },
    {
      title: 'Embreaguez',
      content: 'Minh`alma um dia, sonhou, pensou... Acreditou... Depois de tantas noites de olhos de corujas',
      author: 'Paula do Carmo',
      fullContent: `Minh'alma um dia, sonhou,
pensou... Acreditou...
Depois de tantas noites de olhos de corujas
De lebres ariscas
O coração disse arrisca, arrisca.

E na canção da maré,
a areia deixou-se envolver.
Alguns instantes de sobriedade
Ou novamente a insanidade?

E como em tantas, a razão embriagaste.
E entre lagartas e borboletas
na dúvida vivi a certeza.

Mas a eternidade tem a velocidade da luz.
Lucidez, insanidade... Ilusão da alma

Que sente o coração?
Mareando entre viver e razão,
Entre bênção e maldição...

Onde estava eu?
Não vi que minha alma se enganava?
Que o coração sozinho novamente amava?

De quantos “perais” terei que cair?
De quantos banzeiros vou fugir?
Quantas ribanceiras ainda irei subir?

Mas a areia também se embriaga
Se deixa envolver, abraçar, levar.

É novamente o mar, o amar.
E a areia fica, chora
e novamente ama.

Lucidez, embriaguez, ilusão, razão,
lágrimas... Lágrimas.

Mas se chorar é minha sina,
sou a areia do mar
A árvore das andorinhas.`
    }
  ];
  
  export default poems;
  