import React from 'react';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          Desenvolvido por <strong>Leonardo da Silva Martins</strong>
        </p>
        <p className="footer-text">
          &copy; {new Date().getFullYear()} Todos os direitos reservados.
        </p>
        <div className="footer-links">
          <a href="https://linkedin.com/in/dev-leonardo-martins" target='_blank' rel="noopener noreferrer" className="footer-link">Linkedin</a>
          <a href="https://github.com/leomartins98" target="_blank" rel="noopener noreferrer" className="footer-link">GitHub</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
