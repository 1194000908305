import React from 'react';
import PoemCard from './PoemCard';
import './styles/PoemList.css';
import { Link } from 'react-router-dom';

function PoemList({ poems }) {
  return (
    
    <div className="poem-list">
    {poems.map((poem, index) => (
        <div className="single-card" key={index}>
        <PoemCard 
            title={poem.title} 
            content={poem.content.length > 50 ? poem.content.substring(0, 50) + "..." : poem.content} 
            author={poem.author} 
            fullContent={poem.fullContent} 
        />
        <Link 
            to={{
                pathname: "/poem",
            }}
            state={{ poem: poem }}
            className="btn-read"
        >Ler mais</Link>
    </div>
    ))}
    </div>
  );
}

export default PoemList;
