import './styles/Home.css';

export function Home () {
  return (
    <div className="div-tela">
        <div className="div-parte1">
            <h1>Bem-vindo ao Corujerices!</h1>
            <p>O memorial de Paula do Carmo da Silva Martins. Um site repleto de poemas escritos por essa pessoa incrível e que para sempre ficará em nossas memórias.</p>
        </div>
        <div className="div-parte2">
            <img src="./foto-inicial.jpg" alt="foto-inicial" />
        </div>
    </div>
  );
}