import './styles/PoemDetails.css';
import { useLocation } from 'react-router-dom';

function PoemDetails() {

  const location = useLocation();
  const { poem } = location.state;

    return (
      <div className="single-poem">
        <h5 className="poem-title">{poem.title}</h5>
        <pre className="poem-full-content">{poem.fullContent}</pre>
        <p className="poem-author">- {poem.author}</p>
        <a href="/poems" className='btn-back'>Voltar</a>
      </div>
    );
  }

export default PoemDetails;