// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.css';

function Header() {
  return (
    <div className="head">
      <a href="/" className='btn-title'>Corujerices</a>
      <img src='./capa-header.jpg' alt='Filhos' className='img'></img>
      <p>Desde o nascimento, cada um traz em si a convicção de ser feliz. Contudo, o conceito de felicidade difere em cada um de nós. É esse conceito que nos aproxima ou nos afasta do caminho em direção a esse destino. Acredito que a cada vida que escrevo, construo mais um pedaço do caminho que me conduz à felicidade para a qual nasci, e ao mesmo tempo em que, ao construí-lo, já sou feliz!</p>
      <div className='opcoes-btn'>
      <Link to="/" className="btn-opcao">Início</Link>
        <Link to="/biography" className="btn-opcao">Biografia</Link>
        <Link to="/poems" className="btn-opcao">Poemas</Link>
      </div>
    </div>
  );
}

export default Header;
