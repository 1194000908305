import React from 'react';
import './styles/Biography.css';
import { Link } from 'react-router-dom';

function Biography() {
  return (
    <div className="biography">
      <h2>Paula do Carmo da Silva Martins</h2>
      <div className="container-body">
        <div className="image-container">
            <img src="./foto-biografia.jpg" alt="Descrição da imagem" />
        </div>
        <p>
        Paula do Carmo da Silva Martins, filha de Altair Brandão da Silva e Vivaldo da Silva, nasceu em 20 de fevereiro de 1967, na cidade de Nhamundá, Amazonas. Iniciou seus estudos nesta mesma cidade e desde a primeira infância sempre foi muito estudiosa. Ainda muito pequena, mudou-se para Parintins com seus avós para continuar os estudos. Casou-se com Garcegilson Pontes Martins (Branco) e teve três filhos: Lia Alessandra da Silva Martins, Leonardo da Silva Martins e Lua Aymê da Silva Martins. Paula do Carmo sempre amou e priorizou sua família, cuidando de todos ao seu redor e nunca mediu esforços para fazê-los felizes. Adorava fazer poemas e sempre estudou bastante, nunca se cansava de aprender. Bastante exigente, batalhou por tudo que conquistou, com um carisma impressionante além de muita inteligência.
  A vida de Paula do Carmo foi marcada por desafios, mas também por muitas vitórias. Conseguiu equilibrar sua carreira com a vida pessoal, sempre presente para seus filhos, marido e amigos. Era uma mulher de múltiplas facetas, conseguindo ser uma professora dedicada, uma mãe amorosa e uma esposa companheira. Paula do Carmo formou-se no magistério, graduou-se em Licenciatura em Filosofia e fez especialização em Tecnologia Educacional na UFAM, especialização em Atendimento Educacional Especializado e Formação Continuada em AEE na UFC, cursou Licenciatura em Pedagogia e Mestrado em Educação na UEA.
  A profª Paula do Carmo, dedicou 23 anos de sua vida à educação parintinense, desde 1993, quando iniciou como professora de ensino infantil no Jardim de Infância Pequeninos de Nazaré, passando por várias escolas municipais e estaduais, tais como: Jardim de Infância Alvorada, Escola Municipal Beatriz Maranhão, Escola Municipal Da Paz de Parintins, Escola Municipal Waldemira Bentes, Escola Estadual João Bosco, Escola Estadual Tomaszinho Meirelles, Escola Estadual Brandão de Amorim. Elaborou e implantou inúmeros projetos para o desenvolvimento neural e cognitivo de crianças, adolescentes e jovens. Sua resiliência e criatividade sempre foram admiráveis, influenciando positivamente milhares de estudantes. Paula do Carmo foi uma defensora incansável da educação. Acreditava que o conhecimento era a chave para um futuro melhor e, por isso, envolveu-se em vários projetos sociais, buscando levar oportunidades de aprendizado para todos. Seu empenho fez diferença na vida de muitas pessoas, que a viam como um exemplo de determinação e generosidade.
  A profª Paula do Carmo iniciou sua jornada na Educação inclusiva do Município de Parintins em 2005, ao lado do coordenador profº Manuel Marcos Pires da Silva. Nesse momento passou a dedicar-se a estudar as leis e  buscar cursos na área para atender aos alunos com necessidades especiais da melhor forma. A cada curso, cada experiência com estes alunos, Paula do Carmo se apaixonava ainda mais por esta missão. Em 2008, profª Paula do Carmo, assumiu a Coordenação de Educação Inclusiva da cidade de Parintins, com 164 alunos com deficiência matriculados na Rede Municipal de Ensino. Apesar das dificuldades de compor o time de pioneiros a frente deste trabalho, sua dedicação à educação inclusiva sempre foram contagiantes. Nessa época, profª Paula do Carmo, com o apoio do prefeito Bi Garcia  conseguiu montar a primeira equipe multidisciplinar no município composta por: uma Assistente Social, duas Psicopedagogas institucional, uma profª esp. em língua portuguesa, duas esp. em Educação Especial, uma profª intérprete de Libras e um psicólogo.
  O município de Parintins como pólo do baixo Amazonas, assumiu o compromisso de promover a disseminação dos referenciais políticos pedagógicos e legais que fundamentam a concepção e as práticas pedagógicas e de gestão, voltadas ao desenvolvimento inclusivo da escola. Com este fim, a profª Paula do Carmo juntamente com sua equipe multidisciplinar promoveram: Implantação do Programa Educação Inclusiva: Direito à Diversidade e Curso de Formação Continuada Saberes e Práticas da Inclusão - Ensino Fundamental nos municípios de: Barreirinha, Beruri, Boa Vista do Ramos, Maués, Nhamundá, Borba, Itapiranga, Novo Aripuanã, Silves e Urucará.
  A Profª Paula do Carmo realizou inúmeras capacitações na rede municipal como cursos para formador de braille, curso de libras, seminários de formação de gestores e educadores em educação inclusiva e Workshop do Programa Educação Humanitária: Respeito a todas as formas de vida em parceria com a WSPA. Realizou a 1ª Oficina de Tecnologia Assistiva TA e Comunicação Aumentativa Alternativa CAA. Mudou a estrutura educacional nas escolas municipais, projetando a primeira sala de atendimento educacional especializado (AEE), “sala de recurso”, inaugurada pelo prefeito Bi Garcia, na Escola Escola “Mércia Cardoso Coimbra”. A maneira como a profª Paula do Carmo adaptava às aulas para atender às diversas necessidades dos alunos era extraordinária, transformando o ambiente escolar em um lugar mais acolhedor e acessível para todos.
  Com isso, a profª Paula do Carmo, conseguiu passar para 313 alunos com deficiência matriculados na rede municipal de ensino,  ampliando os atendimentos com a implantação de salas se recursos de TGD e Altas habilidades/ superdotação nas escolas municipais urbanas: Mércia Coimbra, Beatriz Maranhão, Irmã Cristine, Charles Garcia, Luz do Saber, Lila Maia, Waldemira Bentes e Claudemir Carvalho e na área rural nas escolas municipais: João Lauro, hoje Minervina Reis - Comunidade do Zé Açu, Tsukasa Uyetsuka - Vila Amazônia, São Pedro - Paranapanema, Santa Luzia - Macurany, Walkíria Viana - Caburi, Santa Maria Mocambo e Fernando Carvalho - Laguinho. Nas salas de recurso de estimulação precoce dos Centros Educacionais Infantis: Novo Horizonte e Alvorada. Nos espaços de atendimento educacional especializados nas escolas municipais urbanas: Da Paz de Parintins, São Francisco e Nossa Senhora das Graças e na zona rural: São José - Comunidade da Terra Preta, São Sebastião - Comunidade da Brasília, Gláucio Bentes Gonçalves - Paraná do Espírito Santo de Cima, Luiz Gonzaga - Comunidade do Tracajá e São Francisco - Comunidade do Mato Grosso.
  Considerando o aumento de investimentos em políticas de educação inclusiva, realizados pela gestão do prefeito Bi Garcia e o empenho da equipe multifuncional, a profª Paula do Carmo, no ano de 2012, alcançou as práticas de ensino e de aprendizagem e as políticas intersetoriais, contemplando as seguintes áreas: Educação para as Relações Etnicorraciais, Educação Ambiental,  Educação de Jovens e Adultos, Educação do Campo, indígena e quilombola, Educação em direitos humanos e cidadania e Educação especial na perspectiva inclusiva.
  Mesmo com todas as responsabilidades que a vida lhe impôs, Paula nunca deixou de lado sua paixão pela poesia. Seus versos não eram apenas palavras escritas no papel, mas expressões profundas de suas emoções e vivências. Paula não apenas transmitia conhecimento, mas também despertava o interesse e a curiosidade de seus estudantes, incentivando-os a explorar o mundo das palavras e a expressar suas próprias vozes. Nos momentos de lazer, Paula gostava de estar na companhia dos seus, organizando encontros familiares e viagens que ficariam na memória de todos. Seu carisma natural fazia com que estivesse sempre rodeada de amigos, que apreciavam sua companhia e seus conselhos sábios.
  Paula do Carmo amava ler e escrever. Seu sorriso marcante em tudo que se propunha a fazer, ficará pra sempre em nossas memórias. Seu legado continuará a viver através dos inúmeros alunos que inspirou. Seu compromisso com a educação inclusiva foi uma inspiração para todos nós. Sua dedicação incansável à educação e à comunidade mudou a vida de muitas pessoas para melhor. Ao longo de sua vida, Paula do Carmo foi uma mulher que tocou a vida de muitos. Sua dedicação à família, à poesia e à educação deixou um legado duradouro. Mesmo após sua partida, suas lições, seus poemas e o amor que espalhou continuam a inspirar aqueles que tiveram a sorte de conhecê-la. Seu nome permanece como um símbolo de força, sabedoria e carinho, um exemplo de como viver uma vida plena e significativa.

        </p>
      </div>
      <Link to="/" className="btn-back">Voltar</Link>
    </div>
  );
}

export default Biography;
