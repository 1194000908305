import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importando Routes
import Biography from './components/Biography';
import './App.css';
import Header from './components/Header';
import PoemList from './components/PoemList';
import PoemDetails from './components/PoemDetails';

import poems from './data/poems';
import Footer from './components/Footer';
import { Home } from './components/Home';

function App() {

  return (
      <Router>
        <div className="app">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/poems" element={<PoemList poems={poems}/>} />
            <Route path="/biography" element={<Biography />} />
            <Route path="/poem" element={<PoemDetails />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
